import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { getOnlineStatusThunk } from "../store/restaurants";
import AddToCartModal from "../elements/AddToCartModal";
import Header from "../components/Header";
import { isStoreOpen, showTimeError } from "../Utilts/helper";
import Swal from "sweetalert2";

const OnlineOrder = ({restaurant}) => {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch()
  const cardRef = useRef([]);
  const { setShowSignInForm, guestCheckOut, language, changedCart } = useContext(Context);
  const [hoverActive, setHoverActive] = useState(-1);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Add this state
  const currentUser = useSelector((state) => state.session.user);
  const [filteredItems, setFilteredItems] = useState([]);
  const restaurantStatus = useSelector((state) => state.restaurant.onlineStatus);
  const navigate = useNavigate();
  const [currentCart, setCurrentCart] = useState([]);


  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart"));

    if(items){ 
      setCurrentCart(items);
    }
  }, [changedCart]);

  useEffect(() => { 
    dispatch(getOnlineStatusThunk(5))
  }, [dispatch])

  useEffect(() => {
    if (restaurant?.Categories) {
      let allItems = [];
      if (active === 0) {
        // "All" selected, show all items, but filter out hidden items
        allItems = restaurant.Categories.flatMap(category => 
          category.Items.filter(item => !item?.hidden) // Filter out items where hidden is true
        );
      } else {
        // Show items of the selected category, but filter out hidden items
        allItems = restaurant.Categories[active - 1]?.Items.filter(item => !item?.hidden) || [];
      }
      setFilteredItems(allItems);
    }
  }, [active, restaurant]);
  

  

  const handleOpenAddToCartModal = (item) => {
    if(restaurantStatus?.stop_order){ 
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }


    if(isStoreOpen() === false ){
      showTimeError();
    } else {
      setSelectedItem(item); // Set the selected item
      setModal(true);
    }
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <div className="page-content bg-white">
      <Header />

      <section className="content-inner">
      <div className="row mt-3 pl-2 pr-2 justify-content-center" 
                style={{
                  position: 'sticky',
                  top: 64,
                  zIndex: 1000, // Adjust as needed
                  background: 'white', // Add background color if needed
                  width: '100%',
                }}
>
            <div className="col-xl-10 col-lg-9 col-md-12">
              <div
                className="site-filters style-1 clearfix"
                              >
                <ul className="filters">
                  <li
                    className={active === 0 ? "active" : ""}
                    onClick={() => setActive(0)}
                  >
                    <Link to="#">All</Link>
                  </li>
                  {restaurant?.Categories &&
                    restaurant.Categories.map(({ name }, id) => (
                      <li
                        className={active === id + 1 ? "active" : ""}
                        key={id}
                        onClick={() => {
                          setActive(id + 1);
                        }}
                      >
                        <Link to="#" >{name}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        <div className="container">

          <ul id="masonry" className="row">
            {filteredItems.map(
              ({
                id,
                name,
                description,
                price,
                image_url,
                Restaurant_Item_Attributes,
                chinese_name,
              }) => (
                <li
                  className="card-container col-xl-3 col-lg-4 col-md-6 m-b30 "
                  style={{ transition: "all .2s" }}
                  key={id}
                  ref={(node) => {
                    if (node) {
                      cardRef.current.push(node);
                    }
                  }}
                  onClick={() =>
                    handleOpenAddToCartModal({
                      id,
                      name,
                      description,
                      price,
                      image_url,
                      Restaurant_Item_Attributes,
                      chinese_name,
                    })
                  }
                >
                  <div
                    className={`dz-img-box style-4 box-hover  ${
                      hoverActive === id ? "active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onMouseEnter={() => {
                      setHoverActive(id);
                     
                    }}
                  >
                    <div className="menu-detail">
                        {image_url && (
                      <div className="dz-media">
                        <img src={image_url} alt="/" />
                      </div>
                        )}
                      <div className="dz-content">
                        <h5 className="title">
                        <Link style={{fontSize:'18px'}}>
  {language === 'en' ? (name && name.length > 43 ? name.slice(0, 43) + '...' : name) : chinese_name}
</Link>
                        </h5>
                        {/* {description && (
                        <p>{description?.slice(0, 17) + "..."}</p>
                        )} */}
                      </div>
                    </div>
                    <div className="menu-footer">
                      <span>Regular Price</span>
                      <h5 className="price">{price}</h5>
                    </div>
                    <div className="detail-btn" style={{ cursor: "pointer" }}>
                      <i className="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
          <div className="row fixed-bottom text-center bg-white">
        <button onClick={() => navigate('/checkout')} className="btn btn-primary hover-3">View Cart ({currentCart.length})</button>
      </div>
          <AddToCartModal item={selectedItem} modal={modal} setModal={setModal} />
        </div>
      </section>
    </div>
  );
};

export default OnlineOrder;
