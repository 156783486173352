/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import logo from "../assets/images/chefducklogo .png";
import logo2 from "../assets/images/logo2.png";

import background from "../assets/images/bk.png"
import background1 from '../assets/images/background/chefhouseback.jpg'
import sushi1 from '../assets/images/background/sushi1.jpg'
import sushi2 from '../assets/images/background/sushi2.jpg'
import sushi3 from '../assets/images/background/sushi3.jpg'
import sushi4 from '../assets/images/background/sushi4.jpg'
import hengfengapp from '../assets/images/client/hengfengapp.png'
import hengfengnoodle from '../assets/images/client/hengfengnoodles.png'
import hengfengstri from '../assets/images/client/hengfengstrinoodles.png'
import hengfengdrink from '../assets/images/client/hengfengdrink.png'
import hengfengchef from '../assets/images/client/hengfengchefspecial.png'
import hengfengdimsum from '../assets/images/client/hengfengdimsum.png'
import hengfenglogo from '../assets/images/hengfenglogo.png'
import hengfengimage from '../assets/images/noodleimage.png'


export const IMAGES = {

  logo: logo,
  logo2: logo2,
    background: background,
    background1: background1,
    sushi1: sushi1,
    sushi2: sushi2,
    sushi3: sushi3,
    suhi4: sushi4,
    hengfengapp: hengfengapp,
    hengfengnoodle: hengfengnoodle,
    hengfengstri: hengfengstri,
    hengfengdrink: hengfengdrink,
    hengfengchef: hengfengchef,
    hengfengdimsum: hengfengdimsum,
    hengfenglogo: hengfenglogo,
    hengfengimage: hengfengimage

};
