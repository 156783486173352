import { IMAGES } from "../constent/theme"
import Lottie from "react-lottie-player"
import lottieJson from "../assets/json/order-sucess.json"
import { Link, useParams } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { placeOrderThunkPayInStore } from "../store/cart"
import Header from "../components/Header"
import { Context } from "../context/AppContext"

const ComingSoon = () => {
  const dispatch = useDispatch()
  const heartRef = useRef(null)
  const { tip, name, phone, address } = useParams();
  const order = useSelector((state) => state.cart.order);
  const cartItems = JSON.parse(localStorage.getItem('cart'));
    const { changedCart, setChangedCart } = useContext(Context)

  
  const emitOrder = async () => {
    await dispatch(placeOrderThunkPayInStore(5, 'Online', name, phone, +tip, address, cartItems ));
    setChangedCart(!changedCart);
    
  };

  useEffect(() => { 
  
  const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (clientSecret) {
      emitOrder();
    }
  },[tip, name, phone]);

  
  

  return (
    <>
    <Header />
      <Toaster className='mt-5' position="bottom-right" reverseOrder={true} />
      <div className=" mt-5 page-content bg-white">
        <div className="coming-wrapper overflow-hidden">
          <div className="mt-5 container">
            <img
              className="bg-img dz-move"
              src={IMAGES.background_pic17}
              alt="/"
            />
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="inner-content text-center">
                  <h3 className="coming-head"> Order#: {order?.order_id}</h3>
                  <p className="coming-para">
                    {/* Thank you for your order! <br /> */}
                    Thank You will recive SMS shortly with your order updates!
                    <br />
                    <Link to={`/order/${order.order_id}/${3}`}>Click Here To View Order Receipt</Link>
                  </p>
                  <Lottie
                    className="coming-media"
                    loop
                    animationData={lottieJson}
                    play
                    
                  />
                </div>
                <div className="middle-content">
                </div>
                <div className="coming-footer text-center">
                  <p>
                    © Copyrights{" "}
                    <span
                      className="heart"
                      ref={heartRef}
                      onClick={() => {
                        heartRef.current?.classList.toggle("heart-blast")
                      }}
                    ></span>{" "}
                    by{" "}
                    <Link to="/" target="_blank">
                      HuTao Online 
                    </Link>{" "}
                    | 2023 All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ComingSoon
