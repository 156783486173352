import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import video from '../assets/video/video3.mp4';
import hengfeng from '../assets/images/noodleimage.png'


const Home2OurBlog = () => {
  const navigate = useNavigate();

  return (
    <section className="content-inner overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12 m-b30 wow fadeInUp">
            <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `url(${IMAGES.blog_large_pic1})` }}
            >
              <img src={hengfeng}>
              </img>
           
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="section-head">
              <h2 className="title wow flipInX">Our Restaurant</h2>
            </div>
            <div className="testimonial-2">
              <div className="testimonial-detail">
                <div className="testimonial-text wow fadeInUp">
                  <p style={{fontSize: '18px'}}>
                  Welcome to Square on Square Heng Feng Rittenhouse! Our restaurant takes pride in serving authentic Vietnamese cuisine, with our star dish being the renowned Pho.
Pho, a traditional Vietnamese noodle soup, is meticulously crafted in our kitchen. We start with a flavorful broth simmered for hours, infused with aromatic spices like star anise, cinnamon, and cloves. This hearty broth forms the soul of our Pho, providing a rich and comforting base.
At Square on Square Heng Feng Rittenhouse, we strive to deliver an authentic Pho experience that delights the senses and warms the soul. Come savor the taste of Vietnam with us!
                  </p>
                </div>
                <div className="testimonial-info wow fadeInUp"> 
                  <h5 className="testimonial-name">Heng Feng(Rittenhouse Square)</h5>
                  {/* <span className="testimonial-position">By HengFeng</span> */}
                </div>
                <button onClick={() => navigate('/online-order')} className="btn btn-primary mt-4 p-4 text-white">Order Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
