import { IMAGES } from "../constent/theme";

export const MainBannerArr = [
  {
    img: IMAGES.main_slide_pic1,
    imgThumb: 'https://us.123rf.com/450wm/virtosmedia/virtosmedia2302/virtosmedia230254413/198883619-bowl-of-instant-noodle-soup-with-chopsticks-on-dark-background.jpg',
    subtitle: "High Quality Test Station",
    title: "Choosing The",
    title2: "Best",
    title3: "Quality Food",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    img: IMAGES.main_slide_pic1,
    imgThumb: IMAGES.main_slide_thumb_pic2,
    subtitle: "The Best Food Stations",
    title: "Where Food ",
    title2: "Meets",
    title3: "Best Passion",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    img: IMAGES.main_slide_pic1,
    imgThumb: IMAGES.main_slide_thumb_pic3,
    subtitle: "Exploring the Delicious World",
    title: "Delicious Eats",
    title2: "And",
    title3: "Tasty Drinks",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

export const HomeSpacialMenunArr = [
  {
    name: "Pizza",
    price: "$55.00",
    img: IMAGES.gallery_small_pic1,
    title: "Cheese Pizza",
  },
  {
    name: "Rice",
    price: "$50.00",
    img: IMAGES.gallery_small_pic2,
    title: "Fried Rice",
  },
  {
    name: "Green Salad",
    price: "$45.00",
    img: IMAGES.gallery_small_pic3,
    title: "Green Salad",
  },
  {
    name: "Pasta",
    price: "$35.00",
    img: IMAGES.gallery_small_pic4,
    title: "Butterfly Pasta",
  },
];

export const HomeImageBoxArr = [
  {
    price: "$55.00",
    name: "Pasta",
    img: IMAGES.gallery_grid_pic2,
    img2: IMAGES.gallery_grid_pic1,
    icon: "flaticon-restaurant",
    title: "Restaurant",
  },
  {
    price: "$50.00",
    name: "Shake",
    img: IMAGES.gallery_grid_pic5,
    img2: IMAGES.gallery_grid_pic2,
    icon: "flaticon-martini",
    title: "Bar",
  },
  {
    price: "$45.00",
    name: "Dal Fry",
    img: IMAGES.gallery_grid_pic4,
    img2: IMAGES.gallery_grid_pic3,
    icon: "flaticon-coffee-cup",
    title: "Cafe",
  },
  {
    price: "$35.00",
    name: "Pizza",
    img: IMAGES.gallery_grid_pic6,
    img2: IMAGES.gallery_grid_pic4,
    icon: "flaticon-cake",
    title: "Dessert",
  },
];

export const MenuSliderArr = [
  { img: IMAGES.gellry_grid2_pic1, name: "Burger", price: "$20.00" },
  { img: IMAGES.gellry_grid2_pic2, name: "Pasta", price: "$10.00" },
  { img: IMAGES.gellry_grid2_pic3, name: "Tandoor", price: "$15.00" },
  { img: IMAGES.gellry_grid2_pic4, name: "Dal Fry", price: "$65.00" },
  { img: IMAGES.gellry_grid2_pic1, name: "Burger", price: "$20.00" },
];

export const TestymonialArr = [
  {
    name: "John Doe",
    skill: "Food Expert",
    img: IMAGES.testiminial_small_pic1,
  },
  {
    name: "Jolly Roy",
    skill: "Food Tester",
    img: IMAGES.testiminial_small_pic2,
  },
  {
    name: "Thomas Hed",
    skill: "Food Expert",
    img: IMAGES.testiminial_small_pic3,
  },
  {
    name: "Kally Mint",
    skill: "Food Expert",
    img: IMAGES.testiminial_small_pic4,
  },
  {
    name: "Thomas Hed",
    skill: "Food Expert",
    img: IMAGES.testiminial_small_pic5,
  },
  {
    name: "Kally Mint",
    skill: "Food Expert",
    img: IMAGES.testiminial_small_pic6,
  },
];

export const TeamCaroselArr = [
  { img: IMAGES.image_team_pic1, name: "John Doe", position: "Senior Chef" },
  {
    img: IMAGES.image_team_pic2,
    name: "Jemy Carline",
    position: "Junior Chef",
  },
  { img: IMAGES.image_team_pic3, name: "Cotlin Care", position: "Senior Chef" },
  {
    img: IMAGES.image_team_pic4,
    name: "Sarah Albert",
    position: "Senior Chef",
  },
  { img: IMAGES.image_team_pic1, name: "John Doe", position: "Senior Chef" },
];

export const OurBlogArr = [
  { img: IMAGES.blog_grid_pic1, title: "The Spices Route Taste" },
  { img: IMAGES.blog_grid_pic2, title: "The Fork & Knife" },
  { img: IMAGES.blog_grid_pic3, title: "Flavors Of The World" },
  { img: IMAGES.blog_grid_pic4, title: "Taste of Paradise Dishes" },
];

export const MainBanner2Arr = [
  {
    bgimg: IMAGES.background1,
    img1: IMAGES.duck12,
    img2: IMAGES.duck3,
    img3: IMAGES.duck4,
    img4: IMAGES.duck5,
    title: "Pekking Duck",
    name: "Beijing Duck",
    price: "$43.95",
  },
];

export const Home2IconWrapper = [
  { title: "Discount Voucher", icon: "flaticon-offer" },
  { title: "Fresh Healthy Food", icon: "flaticon-hamburger" },
  { title: "Fast Serve On Table", icon: "flaticon-room-service" },
];

export const Home2OurMenuArr = [
  { img: IMAGES.menu_small_pic1, name: "Burger", price: "$10.00" },
  { img: IMAGES.menu_small_pic2, name: "Hot Rice", price: "$15.00" },
  { img: IMAGES.menu_small_pic3, name: "Momos", price: "$25.00" },
  { img: IMAGES.menu_small_pic4, name: "Pasta", price: "$45.00" },
  { img: IMAGES.menu_small_pic1, name: "Burger", price: "$10.00" },
];

export const Home2SpacialMenuArr = [
  { img: IMAGES.gallery_grid3_pic1, name: "Pepperoni", price: "$15.00" },
  { img: IMAGES.gallery_grid3_pic2, name: "Pancake stack", price: "$18.00" },
  { img: IMAGES.gallery_grid3_pic3, name: "Halumini", price: "$10.00" },
  { img: IMAGES.gallery_grid3_pic4, name: "Eggs", price: "$12.00" },
];

export const Home2AreMenuArr = [
  {
    img: IMAGES.menu_small_grid_pic1,
    name: "Pulled Chicken SandWich",
    price: " $30",
    categery: "All PIZZA SALAD SWEETS",
  },
  {
    img: IMAGES.menu_small_grid_pic2,
    name: "Canada Dry Ginger Ale",
    price: " $60",
    categery: "All  COLD DRINK SALAD SWEETS",
  },
  {
    img: IMAGES.menu_small_grid_pic3,
    name: "Martinelli’s Apple Juice",
    price: " $80",
    categery: "All PIZZA SPICY BURGER ",
  },
  {
    img: IMAGES.menu_small_grid_pic4,
    name: "Mango Mania Smoothie",
    price: " $30",
    categery: "All COLD DRINK SWEETS SPICY",
  },
  {
    img: IMAGES.menu_small_grid_pic5,
    name: "BBQ Chicken Sandwich",
    price: " $30",
    categery: "All PIZZA SALAD SWEETS BURGER",
  },
  {
    img: IMAGES.menu_small_grid_pic6,
    name: "Honey Mustard Pasta",
    price: " $20",
    categery: "All PIZZA SPICY",
  },
  {
    img: IMAGES.menu_small_grid_pic7,
    name: "Chicken Honey Plate",
    price: " $80",
    categery: "All COLD DRINK SALAD SWEETS SPICY",
  },
  {
    img: IMAGES.menu_small_grid_pic8,
    name: "Jamaican Jerk Sandwich",
    price: " $80",
    categery: "All COLD DRINK SWEETS SPICY BURGER",
  },
];

export const Home2TeamArr = [
  { img: IMAGES.image_team_pic1 },
  { img: IMAGES.image_team_pic2 },
  { img: IMAGES.image_team_pic3 },
  { img: IMAGES.image_team_pic4 },
];

export const Home2TestimonialArr = [
  // {
  //   img: IMAGES.hengfengapp,
  //   name: "Apptizer",
  //   position: "Food Expert",
  //   desc:`Introducing Appetizers at Square on Square Heng Feng Rittenhouse: A Prelude to Culinary Delight Dive into flavor with our tantalizing appetizers. From crunchy spring rolls to succulent skewers, each bite is a taste sensation that sets the stage for an unforgettable dining experience. Whether you're sharing with friends or savoring solo, our appetizers promise to delight your senses and leave you craving more.`
  // },
  {
    img: IMAGES.hengfengdimsum,
    name: "Dimsum",
    position: "Food Expert",
    desc:`Savor Dim Sum at Square on Square Heng Feng Rittenhouse: Shrimp Dumplings, Siu Mai, Xiao Long Bao

    Embark on a journey of taste with our exquisite dim sum selection. Indulge in the succulent sweetness of shrimp dumplings, the savory perfection of Siu Mai, and the flavorful burst of Xiao Long Bao. Join us at Square on Square Heng Feng Rittenhouse for a culinary experience that promises to delight your palate and awaken your senses.`
  },
  {
    img: IMAGES.hengfengstri,
    name: "Stir-Fried Noodles",
    position: "Food Expert",
    desc:`Satisfy Your Cravings with Wok-Tossed Noodles at Square on Square Heng Feng Rittenhouse

    Tantalize your taste buds with the irresistible flavors of our wok-tossed noodles. Prepared with precision and expertise, our noodles are stir-fried to perfection, capturing the essence of traditional Asian cuisine. Whether you crave the bold spiciness of Singapore noodles, the umami richness of lo mein, or the delicate balance of flavors in pad Thai, our menu offers a variety of options to satisfy every palate. Join us at Square on Square Heng Feng Rittenhouse and experience the art of wok-tossed noodles like never before.`
  },
  // {
  //   img: IMAGES.hengfengchef,
  //   name: "Chefs Special",
  //   position: "Food Expert",
  //   desc:`Experience Chef's Specials at Square on Square Heng Feng Rittenhouse

  //   Delight in the culinary masterpieces curated by our chef, featuring an array of tantalizing dishes that will transport your taste buds to the heart of Asia. Indulge in the iconic flavors of Beijing Roast Duck, the aromatic tenderness of Sesame Chicken, and the irresistible crunch of Walnut Shrimp. Savor the bold spices of Mongolian Beef and the comforting heat of Mapo Tofu. Join us at Square on Square Heng Feng Rittenhouse and embark on a culinary journey filled with unforgettable flavors and exquisite delights.`
  // },
  
];

export const Home3ServicesArr = [
  { icon: "flaticon-fast-delivery", content: "24/7 Free Delivery" },
  {
    icon: "flaticon-clock-1",
    content: "Our Restaurant is Open Around the Clock",
  },
  { icon: "flaticon-chef", content: "Best Chef" },
  { icon: "flaticon-cuisine", content: "We Have The Freshest Product" },
];









export const Home3OurMenuArr = [
  {
    img: IMAGES.images_gallery_grid4_pic1,
    name: "Burger",
    price: " $30",
    categery: "All PIZZA SALAD SWEETS",
  },
  {
    img: IMAGES.images_gallery_grid4_pic2,
    name: "Chicken Burger",
    price: " $60",
    categery: "All  COLD DRINK SALAD SWEETS",
  },
  {
    img: IMAGES.images_gallery_grid4_pic3,
    name: "Pineapple Pizza",
    price: " $80",
    categery: "All PIZZA SPICY BURGER ",
  },
  {
    img: IMAGES.images_gallery_grid4_pic4,
    name: "Pineapple Soup",
    price: " $30",
    categery: "All COLD DRINK SWEETS SPICY",
  },
  {
    img: IMAGES.images_gallery_grid4_pic5,
    name: "Momos",
    price: " $30",
    categery: "All PIZZA SALAD SWEETS BURGER",
  },
  {
    img: IMAGES.images_gallery_grid4_pic6,
    name: "Pancake",
    price: " $20",
    categery: "All PIZZA SPICY",
  },
];

export const AboutServiceArr = [
  { icon: "flaticon-vegetable", title: "Fresh Products" },
  { icon: "flaticon-chef-hat", title: "Master Chefs" },
  { icon: "flaticon-cocktail", title: "Refreshing Beverages" },
  { icon: "flaticon-cuisine", title: "Japanese Cuisine" },
];

export const FaqArr = [
  { title: "Q1: What are your hours of operation?", evantK: 1 },
  { title: "Q2: What is your menu like?", evantK: 2 },
  { title: "Q3: Do you have vegetarian/vegan/gluten-free options?", evantK: 3 },
  { title: "Q4: Do you offer takeout or delivery?", evantK: 4 },
  { title: "Q5: Can I make a reservation? How do I do that?", evantK: 5 },
  { title: "Q6: Is your restaurant kid-friendly?", evantK: 6 },
  { title: "Q7: What is your menu like?", evantK: 7 },
];

export const teamArr = [
  { img: IMAGES.image_team_pic1, name: "Sarah Albert" },
  { img: IMAGES.image_team_pic2, name: "Raiyan Kovin" },
  { img: IMAGES.image_team_pic3, name: "John Doe" },
  { img: IMAGES.image_team_pic4, name: "Jemy Carline" },
  { img: IMAGES.image_team_pic5, name: "Cotlin Care" },
  { img: IMAGES.image_team_pic6, name: "Stevin Mark" },
  { img: IMAGES.image_team_pic7, name: "John Doe" },
  { img: IMAGES.image_team_pic8, name: "Raiyan Kovin" },
  { img: IMAGES.image_team_pic9, name: "Jemy Carline" },
  { img: IMAGES.image_team_pic10, name: "John Doe" },
  { img: IMAGES.image_team_pic11, name: "Martin Doe" },
  { img: IMAGES.image_team_pic12, name: "Stevin Mark" },
];

export const TeamDetailArr = [
  {
    img: IMAGES.gallery_grid5_pic1,
    title: "Sweet Cake",
    progress: "60%",
    position: "Skills",
  },
  {
    img: IMAGES.gallery_grid5_pic2,
    title: "Chicken",
    progress: "85%",
    position: "Signature Dishes",
  },
  {
    img: IMAGES.gallery_grid5_pic3,
    title: "Salad",
    progress: "95%",
    position: "Customer Satisfied",
  },
  {
    img: IMAGES.gallery_grid5_pic4,
    title: "Burger",
    progress: "75%",
    position: "Communication Skills",
  },
];

export const TestimonialArr = [
  { img: IMAGES.testimonial_large_pic1, diraction: "left", name: "John Doe" },
  { img: IMAGES.testimonial_large_pic2, name: "Carry Mint" },
  {
    img: IMAGES.testimonial_large_pic3,
    diraction: "left",
    name: "Sarah Albert",
  },
  { img: IMAGES.testimonial_large_pic1, name: "Stevin Mark" },
];

export const ServicesArr = [
  { icon: "flaticon-vegetable", name: "Fresh Products" },
  { icon: "flaticon-chef-hat", name: "Skilled Chefs" },
  { icon: "flaticon-cocktail", name: "Best Bar" },
  { icon: "flaticon-cuisine", name: "Vegan Cuisine" },
  { icon: "flaticon-support", name: "Free Supports" },
  { icon: "flaticon-room-service", name: "Fast Serve On" },
  { icon: "flaticon-pot", name: "Fresh Healthy Food" },
  { icon: "flaticon-fast-delivery", name: "Quick Delivery" },
  { icon: "flaticon-token", name: "Order Tokan" },
  { icon: "flaticon-furniture", name: "Table Service" },
  { icon: "flaticon-team", name: "Awesome Team" },
  { icon: "flaticon-offer", name: "Discount Voucher" },
];

export const MenuStyle1Arr = [
  {
    title: "Starters",
    child: [
      { categery: "Creamy Mushroom Soup", price: "$10" },
      { categery: "Stuffed Mushrooms", price: "$14" },
      { categery: "Grilled Caesar Salad", price: "$18" },
      { categery: "Signature Autumn Salad", price: "$25" },
      { categery: "Glazed Tomato Bruschite", price: "$35" },
    ],
  },
  {
    title: "Seafood",
    child: [
      { categery: "Baked Oysters Rockefeller", price: "$13" },
      { categery: "Grilled Shrimp Skewers", price: "$15" },
      { categery: "Seafood Linguine", price: "$14" },
      { categery: "Shrimp Scampi", price: "$20" },
    ],
  },
];
export const MenuStyle1Arr2 = [
  {
    title: "Drinks",
    child: [
      { categery: "Cranberry Juice", price: "$10" },
      { categery: "Hot Chocolate", price: "$14" },
      { categery: "Lemon-Lime Soda", price: "$18" },
      { categery: "Alcoholic beverages", price: "$25" },
    ],
  },
  {
    title: "Specials",
    child: [
      { categery: "Beef Tenderloin & Red Wine", price: "$13" },
      { categery: "Mushroom Risotto", price: "$15" },
      { categery: "Lobster Thermidor", price: "$14" },
      { categery: "Beef Bourguignon", price: "$20" },
    ],
  },
  {
    title: "Meat",
    child: [
      { categery: "Beef Bourguignon", price: "$30" },
      { categery: "Barbecue Ribs", price: "$28" },
      { categery: "Roasted Turkey", price: "$40" },
      { categery: "Beef Stroganoff", price: "$92" },
    ],
  },
];

export const MenuStyle2Arr = [
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.gallery_grid5_pic1,
    name: "Sweet Cake",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_grid5_pic2,
    name: "Health And Wealth",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.gallery_grid5_pic3,
    name: "Boil Rice",
  },
  {
    categery: "ALL PIZZA BURGER",
    img: IMAGES.gallery_grid5_pic4,
    name: "Burger & Frence",
  },
  {
    categery: "ALL COLD DRINK SPICY",
    img: IMAGES.gallery_grid5_pic5,
    name: "Nonveg Biryani",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.gallery_grid5_pic6,
    name: "Honey Bread",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_grid5_pic7,
    name: "Sweet Potato",
  },
  {
    categery: "ALL COLD DRINK SALAD",
    img: IMAGES.gallery_grid5_pic8,
    name: "Veg Sauce",
  },
  {
    categery: "ALL COLD DRINK BURGER",
    img: IMAGES.gallery_grid5_pic9,
    name: "Chillaa",
  },
  {
    categery: "ALL SWEETS SPICY",
    img: IMAGES.gallery_grid5_pic10,
    name: "Crapse food",
  },
  {
    categery: "ALL PIZZA BURGER",
    img: IMAGES.gallery_grid5_pic11,
    name: "Pizzaa",
  },
  {
    categery: " ALL COLD DRINK SWEETS SPICY",
    img: IMAGES.gallery_grid5_pic12,
    name: "Donetsa",
  },
];
export const MenuStyle3Arr = [
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.images_gallery_grid4_pic1,
    name: "Burger",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.images_gallery_grid4_pic2,
    name: "Chicken Burger",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.images_gallery_grid4_pic3,
    name: "Pineapple Pizza",
  },
  {
    categery: "ALL PIZZA BURGER",
    img: IMAGES.images_gallery_grid4_pic4,
    name: "Pineapple Soup",
  },
  {
    categery: "ALL COLD DRINK SPICY",
    img: IMAGES.images_gallery_grid4_pic5,
    name: "Momos",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.images_gallery_grid4_pic6,
    name: "Pancake",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.images_gallery_grid4_pic3,
    name: "Pineapple Pizza",
  },
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.images_gallery_grid4_pic1,
    name: "Spicy Burger",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.images_gallery_grid4_pic2,
    name: "Cheese Burger",
  },
];

export const MenuStyle4Arr = [
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.menu_small_pic1,
    name: "Burger",
    price: "$10.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic2,
    name: "Chicken Burger",
    price: "$15.00",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.menu_small_pic3,
    name: "Pineapple Pizza",
    price: "$25.00",
  },
  {
    categery: "ALL PIZZA BURGER",
    img: IMAGES.menu_small_pic4,
    name: "Pineapple Soup",
    price: "$45.00",
  },
  {
    categery: "ALL COLD DRINK SPICY",
    img: IMAGES.menu_small_pic5,
    name: "Momos",
    price: "$20.00",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.menu_small_pic6,
    name: "Pancake",
    price: "$90.00",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.menu_small_pic7,
    name: "Pineapple Pizza",
    price: "$20.00",
  },
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.menu_small_pic8,
    name: "Spicy Burger",
    price: "$05.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic9,
    name: "Cheese Burger",
    price: "$82.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic10,
    name: "Cheese Burger",
    price: "$16.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic11,
    name: "Cheese Burger",
    price: "$22.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic12,
    name: "Cheese Burger",
    price: "$90.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic13,
    name: "Cheese Burger",
    price: "$20.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic14,
    name: "Cheese Burger",
    price: "$90.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.menu_small_pic15,
    name: "Cheese Burger",
    price: "$35.00",
  },
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.menu_small_pic8,
    name: "Spicy Burger",
    price: "$10.00",
  },
];

export const MenuStyle5Arr = [
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.gallery_small_pic1,
    name: "Pizza",
    price: "$55.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_small_pic2,
    name: "Rice",
    price: "$50.00",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.gallery_small_pic3,
    name: "Green Salad Pizza",
    price: "$45.00",
  },
  {
    categery: "ALL PIZZA BURGER",
    img: IMAGES.gallery_small_pic4,
    name: "Pasta Soup",
    price: "$40.00",
  },
  {
    categery: "ALL COLD DRINK SPICY",
    img: IMAGES.gallery_small_pic5,
    name: "Momos",
    price: "$35.00",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.gallery_small_pic6,
    name: "Paneer",
    price: "$25.00",
  },
  {
    categery: "ALL COLD DRINK SWEETS",
    img: IMAGES.gallery_small_pic7,
    name: "Macrony ",
    price: "$22.00",
  },
  {
    categery: "ALL PIZZA SPICY",
    img: IMAGES.gallery_small_pic8,
    name: "Cury Rice",
    price: "$60.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_small_pic9,
    name: "Aloo Sticks ",
    price: "$22.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_small_pic10,
    name: "Fry Fish ",
    price: "$30.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_small_pic11,
    name: "Veg Soup ",
    price: "$36.00",
  },
  {
    categery: "ALL SALAD BURGER",
    img: IMAGES.gallery_small_pic12,
    name: "Noodles",
    price: "$90.00",
  },
];

export const Shop1RightContentArr = [
  { img: IMAGES.shop_pic1 },
  { img: IMAGES.shop_pic2 },
  { img: IMAGES.shop_pic3 },
  { img: IMAGES.shop_pic4 },
  { img: IMAGES.shop_pic1 },
  { img: IMAGES.shop_pic2 },
];

export const shopArr = [
  { img: IMAGES.shop_pic1, num: 1, title: "Double Patty Burger" },
  { img: IMAGES.shop_pic2, num: 1, title: "All Combo" },
  { img: IMAGES.shop_pic3, num: 1, title: "Veg And crispy Burger" },
];

export const BlogGrid2Arr = [
  {
    img: IMAGES.blog_grid3_pic1,
    img2: IMAGES.blog_grid_pic1,
    title: "Paradise Taste of Dishes",
  },
  {
    img: IMAGES.blog_grid3_pic2,
    img2: IMAGES.blog_grid_pic2,
    title: "Taste of Paradise Dishes",
  },
  {
    img: IMAGES.blog_grid3_pic3,
    img2: IMAGES.blog_grid_pic3,
    title: "The Fork & Knife",
  },
  {
    img: IMAGES.blog_grid3_pic4,
    img2: IMAGES.blog_grid_pic4,
    title: "Flavors Of The World",
  },
  {
    img: IMAGES.blog_grid3_pic5,
    img2: IMAGES.blog_grid_pic5,
    title: "Tabletop Treats Cheese",
  },
  {
    img: IMAGES.blog_grid3_pic6,
    img2: IMAGES.blog_grid_pic6,
    title: "Palate Pleaser Dishes",
  },
];
export const BlogGrid3Arr = [
  { img: IMAGES.blog_grid3_pic1, title: "Paradise Taste of Dishes" },
  { img: IMAGES.blog_grid3_pic2, title: "Taste of Paradise Dishes" },
  { img: IMAGES.blog_grid3_pic3, title: "The Fork & Knife" },
  { img: IMAGES.blog_grid3_pic4, title: "Flavors Of The World" },
  { img: IMAGES.blog_grid3_pic5, title: "Tabletop Treats Cheese" },
  { img: IMAGES.blog_grid3_pic6, title: "Palate Pleaser Dishes" },
  { img: IMAGES.blog_grid3_pic7, title: "Paradise Taste of Dishes" },
  { img: IMAGES.blog_grid3_pic8, title: "Taste of Paradise Dishes" },
  { img: IMAGES.blog_grid3_pic1, title: "The Fork & Knife" },
];

export const BlogGrid3MasonaryArr = [
  { img: IMAGES.blog_grid3_pic1 },
  { img: IMAGES.blog_grid3_pic2 },
  { img: IMAGES.blog_grid3_pic3 },
];

export const BlogWideListSidebarArr = [
  { img: IMAGES.blog_grid3_pic1 },
  { img: IMAGES.blog_grid3_pic2 },
  { img: IMAGES.blog_grid3_pic3 },
  { img: IMAGES.blog_grid3_pic4 },
  { img: IMAGES.blog_grid3_pic5 },
  { img: IMAGES.blog_grid3_pic6 },
  { img: IMAGES.blog_grid3_pic7 },
  { img: IMAGES.blog_grid3_pic8 },
];

export const ContactUsArr = [
  {
    icon: "flaticon-clock-1",
    title: "Open & Closing",
    text: (
      <p>
   Mon - Sun: 11:00-22:30PM<br />
   Tuesday: Closed

      </p>
    ),
  },
  {
    icon: "flaticon-placeholder",
    title: "Location",
    text: (
      <p>
                        274 S 20th St, Philadelphia, PA 19103                     
      </p>
    ),
  },
  {
    icon: "flaticon-telephone",
    title: "Phone Number",
    text: (
      <p>
                        (215) 568-0088

      </p>
    ),
  },

  {
    icon: "flaticon-email-1",
    title: "Email Address",
    text: (
      <p>
        na
      </p>
    ),
  },
 
];

export const SwitcherButtonsArr = [
  { setStyle: "skin-1", skin: "color_1" },
  { setStyle: "skin-2", skin: "color_2" },
  { setStyle: "skin-3", skin: "color_3" },
  { setStyle: "skin-4", skin: "color_4" },
  { setStyle: "skin-5", skin: "color_5" },
  { setStyle: "skin-6", skin: "color_6" },
  { setStyle: "skin-7", skin: "color_7" },
];

export const SwitcherLayoutArr = [
  { title: "wide-layout" },
  { title: "boxed" },
  { title: "frame" },
];

export const BackGroundColorArr = [
  { colorValue: "color_0", setStyle: "bg-color-0" },
  { colorValue: "color_1", setStyle: "bg-color-1" },
  { colorValue: "color_3", setStyle: "bg-color-2" },
  { colorValue: "color_2", setStyle: "bg-color-3" },
  { colorValue: "color_4", setStyle: "bg-color-4" },
  { colorValue: "color_5", setStyle: "bg-color-5" },
  { colorValue: "color_6", setStyle: "bg-color-6" },
  { colorValue: "color_7", setStyle: "bg-color-7" },
];

export const BgSmallImgArr = [
  {
    img: IMAGES.switcher_small_bg1,
    imgLarge: IMAGES.switcher_large_bg1,
    ptSmall: IMAGES.switcher_small_pt1,
    ptLarge: IMAGES.switcher_large_pt1,
  },
  {
    img: IMAGES.switcher_small_bg2,
    imgLarge: IMAGES.switcher_large_bg2,
    ptSmall: IMAGES.switcher_small_pt2,
    ptLarge: IMAGES.switcher_large_pt2,
  },
  {
    img: IMAGES.switcher_small_bg3,
    imgLarge: IMAGES.switcher_large_bg3,
    ptSmall: IMAGES.switcher_small_pt3,
    ptLarge: IMAGES.switcher_large_pt3,
  },
  {
    img: IMAGES.switcher_small_bg4,
    imgLarge: IMAGES.switcher_large_bg4,
    ptSmall: IMAGES.switcher_small_pt4,
    ptLarge: IMAGES.switcher_large_pt4,
  },
  {
    img: IMAGES.switcher_small_bg5,
    imgLarge: IMAGES.switcher_large_bg5,
    ptSmall: IMAGES.switcher_small_pt5,
    ptLarge: IMAGES.switcher_large_pt5,
  },
  {
    img: IMAGES.switcher_small_bg6,
    imgLarge: IMAGES.switcher_large_bg6,
    ptSmall: IMAGES.switcher_small_pt6,
    ptLarge: IMAGES.switcher_large_pt6,
  },
  {
    img: IMAGES.switcher_small_bg7,
    imgLarge: IMAGES.switcher_large_bg7,
    ptSmall: IMAGES.switcher_small_pt7,
    ptLarge: IMAGES.switcher_large_pt7,
  },
];
