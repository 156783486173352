import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { deleteItemThunk, getCartThunk, placeOrderThunkPayInStore } from "../store/cart";
import CheckoutApp from "../elements/StripeCheckoutApp.js";
import Header from "../components/Header.js";
import { Modal, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import EditCartModal from "../elements/EditCartModal.js";
import { Context } from "../context/AppContext";
import Lottie from "react-lottie-player";
import loader from "../assets/json/loader.json";
import { isStoreOpen, showTimeError } from "../Utilts/helper";
import Swal from "sweetalert2";
import { getOnlineStatusThunk } from "../store/restaurants";
import emptyCartLottie from "../assets/json/emptycart.json"

const opetion2 = [
  // { value: "Credit Card Type", label: "Online Payment" },
  { value: "in-store", label: "In Store" },
];

// const opetion1 = [
//   { value: "Credit Card Type", label: "Online Payment" },
// ]

const MenuList = () => {
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(opetion2[0]); // Initialize with the default value
  const restaurant = useSelector((state) => state.restaurant.restaurant);
  const [selectedTipPercentage, setSelectedTipPercentage] = useState(15); // Default tip percentage
  const [customTipAmount, setCustomTipAmount] = useState(0); // State to track custom tip amount
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); 
  const { setTipAmount, language, changedCart, setChangedCart} = useContext(Context)
  const [name , setName] = useState('')
  const [phone , setPhone] = useState('')
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(false);
  const restaurantStatus = useSelector((state) => state.restaurant.onlineStatus);
  const [needDelivery, setNeedDelivery] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [addressVerified, setAddressVerified] = useState(false);
  const [currentUserAddress, setCurrentUserAddress] = useState(null);
  const [addressErrors, setAddressErrors] = useState(null);
  
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getOnlineStatusThunk(5));
}, [dispatch])

useEffect(() => { 
  const cart = JSON.parse(localStorage.getItem('cart'));
  if(cart){
    setCartItems(cart);
  }
}, [changedCart])
  
  useEffect(() => { 
    if(needDelivery){ 
      handleScriptLoad()
      handleStoreMap()
    }else { 
      handleStoreMap()
    }
  },[needDelivery, currentUserAddress])
 
  //set tip amount for gobal state
  useEffect(() => {
    setTipAmount(calculateTotalWithTip(cartItems, selectedTipPercentage).tipAmount);
  }, [selectedTipPercentage, cartItems, setTipAmount]);

  const handleEditCartModal = (item) => { 
    if(restaurantStatus?.stop_order){ 
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(!isStoreOpen()){
      showTimeError();   
      return 
    }
    setSelectedItem(item);
    setModal(true);
  }

  const handleContinueCheckout = () => {
    if(restaurantStatus?.stop_order){ 
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }
    
    if(!name) return setError('Name is required')
    if(!phone) return setError('Phone is required')
    if(!phone.match(/^\d{10}$/)) return setError('Phone number is invalid')

    setError(null)
    setActiveTab(true)
  }

  const goBack = () => {
    setActiveTab(false)
  }
 
  // Function to handle click on custom tip option
  const handleCustomTipClick = () => {
    setSelectedTipPercentage(0);
    setShowCustomModal(true);
  };

  const handleIncrease = () => {
    setCustomTipAmount(prevAmount => prevAmount + 1);
  };

  const handleDecrease = () => {
    if (customTipAmount > 0) {
      setCustomTipAmount(prevAmount => prevAmount - 1);
    }
  };

  // Function to handle click on predefined tip options
  const handleTipOptionClick = (tipPercentage) => {
    setCustomTipAmount(0); // Reset custom tip amount
    setSelectedTipPercentage(tipPercentage);
  };

  // Function to handle submission of custom tip in modal
  const handleCustomTipSubmit = () => {
    setShowCustomModal(false);
  };

  const handlePaymentMethodChange = (selectedPaymentMethod) => {
    setSelectedPaymentMethod(selectedPaymentMethod);
  };

  const handlePayInStore = async () => {
    if(needDelivery && selectedPaymentMethod.value === 'in-store'){
      setShowPaymentModal(false);
      Swal.fire({
        icon: 'error',
        title: 'Delivery Required',
        text: 'Please select online payment for delivery',
      })
      return;
    }
    setLoading(true);
    try {
        await dispatch(placeOrderThunkPayInStore(
          restaurant.id, 
          selectedPaymentMethod.value, 
          name, 
          phone, 
          calculateTotalWithTip(cartItems, selectedTipPercentage).tipAmount, 
          needDelivery ? currentUserAddress : 'Pickup', 
          cartItems
          ))
        .then(() => localStorage.removeItem('cart'))
      // Set loading to false after the payment process is completed
      setLoading(false);
      // Navigate to the order confirmed page with query parameters
      navigate(`/order-confirmed/1/'tzdsar'/4215/dsafsarwq`);
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle error if necessary
      // Set loading to false in case of error
      setLoading(false);
    } finally {
      // Disconnect from the Socket.IO server when the component unmounts
      // This is important to prevent memory leaks

    }
  };
  

  const calculateTotal = (cartItems) => {
   //calcuate all items total with attribute
    let subtotal = cartItems.reduce(
      (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
      0
    );

    cartItems.forEach((cartItem) => {
      cartItem.options.forEach((option) => {
        subtotal += option.priceModifier * cartItem.quantity;
      });
    });


    const tax = subtotal * (restaurant?.Restaurant_Fees[0]?.tax || 0);
    let service_fee = subtotal * (restaurant?.Restaurant_Fees[0]?.service_fee || 0);
    const convenienceFee = parseFloat(
      restaurant?.Restaurant_Fees[0]?.commission_fee || 0
    );
    let total = subtotal + tax + convenienceFee 

    const deliveryFee = restaurant?.Restaurant_Fees[0]?.delivery_fee || 0;

    if(needDelivery){
      total += +deliveryFee;
      service_fee += convenienceFee
    }


    return {
      subtotal: subtotal.toFixed(2),
      tax: tax.toFixed(2),
      convenienceFee: convenienceFee.toFixed(2),
      total: total.toFixed(2),
      deliveryFee: deliveryFee,
      service_fee: service_fee.toFixed(2)
    };
  };

  const calculateTotalWithTip = (cartItems, tipPercentage) => {
    let subtotal = cartItems.reduce(
      (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
      0
    );

    cartItems.forEach((cartItem) => {
      cartItem.options.forEach((option) => {
        subtotal += option.priceModifier * cartItem.quantity;
      });
    });

    
    const tax = subtotal * (restaurant?.Restaurant_Fees[0]?.tax || 0);
    const service_fee = subtotal * (restaurant?.Restaurant_Fees[0]?.service_fee || 0);
    const convenienceFee = parseFloat(
      restaurant?.Restaurant_Fees[0]?.commission_fee || 0
    );
    const deliveryFee = restaurant?.Restaurant_Fees[0]?.delivery_fee || 0;

    // Calculate tip amount based on percentage or custom
    const tipAmount = customTipAmount
    ? customTipAmount
    : subtotal * (tipPercentage / 100);

    let total = subtotal + tax + convenienceFee + tipAmount;
    
    if(needDelivery){
      total += +deliveryFee + service_fee;
    }
    

    return {
      tipAmount: tipAmount.toFixed(2),
      total: total.toFixed(2),
    };
  };

  const handleDeleteItem = (itemId) => {
    if(restaurantStatus?.stop_order){
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(!isStoreOpen()){
      showTimeError();
    }

    const localCart = JSON.parse(localStorage.getItem('cart'));
    const updatedCart = localCart.filter(item => item.Item.id !== itemId);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setChangedCart(!changedCart);
    
  };

  const handleProceedToPayment = () => {
    if(restaurantStatus?.stop_order){
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(!isStoreOpen()){
      showTimeError();
      return
    }
    setShowPaymentModal(true);
  }
  
  function getItemAttributeNameById(id, item) {
    // Iterate over Restaurant_Item_Attributes array
    for (const attribute of item.Restaurant_Item_Attributes) {
      // Iterate over Item_Attribute_Options array within each Item_Attribute
      for (const option of attribute.Item_Attribute.Item_Attribute_Options) {
        // Check if the id matches
        if (option.id === id) {
          return option.name; // Return the name if id matches
        }
      }
    }
    // Return null if no matching id is found
    return null;
  }

  const calculateItemTotal = (item) => {
    let total = item?.Item?.price * item?.quantity;
    item?.options?.forEach((option) => {
      total += option.priceModifier * item?.quantity;
    });
    return total.toFixed(2);
  }
  
  


  let autocomplete;
  const handleScriptLoad = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    );

    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  function handlePlaceSelect() {
    let place = autocomplete.getPlace();

    if(!place.geometry){ 
      setAddressErrors('Please enter a valid address')
      
      document.getElementById("autocomplete").value = "";

      return;
    }else { 
      
      const res = checkDistance(place.formatted_address)
      res.then((result) => {
        if(result){
          setCurrentUserAddress(place.formatted_address);
          setAddressVerified(true);
          setAddressErrors('');
        }
      })
    }
  }

  const checkDistance = async (userAddress) => {
    const geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: userAddress }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const userLocation = results[0].geometry.location;
          const restaurantLocation = new google.maps.LatLng(39.9483417,-75.1770881);
  
          // Calculate distance between user's location and restaurant's location
          const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(userLocation, restaurantLocation);
          const distanceInMiles = distanceInMeters * 0.000621371; // Convert meters to miles
  
          // Check if the distance is within 4 miles
          if (distanceInMiles <= 4) {
            // Address is within 4 miles, proceed with delivery
            setAddressErrors('');
            setAddressVerified(true);
            resolve(true);
          } else {
            // Address is more than 4 miles away
            setAddressErrors('Sorry, we cannot deliver to places more than 4 miles away.');
            setAddressVerified(false);
            resolve(false);
          }
        } else {
          // Geocoding failed, show an error
          setAddressErrors('Unable to geocode the address. Please try again later.');
          setAddressVerified(false);
          resolve(false);
        }
      });
    });
};

  

  const handleStoreMap = () => {
  
    if (needDelivery) {
      /*global google*/ // To disable any eslint 'google not defined' errors
      const mapOptions = {
        center: new google.maps.LatLng(39.94834242619462, -75.17451004382549),
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      const map = new google.maps.Map(document.getElementById("gmp-map"), mapOptions);
    
      // Add marker
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(39.94834242619462, -75.17451004382549),
        title: "Rainbow Sushi",
        map: map
      });
  
  
      // Add circle overlay
      const circle = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: map,
        center: new google.maps.LatLng(39.94834242619462, -75.17451004382549),
        radius: 6437.36 // 4 miles in meters
      });
    } else {
      const mapOptions = {
        center: new google.maps.LatLng(39.94834242619462, -75.17451004382549),
        zoom: 18,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      const map = new google.maps.Map(document.getElementById("gmp-map"), mapOptions);
    
      // Add marker
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(39.94834242619462, -75.17451004382549),
        title: "Rainbow Sushi",
        map: map
      });
    }
  }
  
 

  return (
    <>
   <div className="py-5 mt-5">
    <div className="row justify-content-center align-items-center">
      <div className="col">
        <div className="card">
          <div className="card-body p-0">
            <div className="row">
              <Link to="/online-order" className="text-decoration-none text-dark">
                <h5 style={{ cursor: 'pointer' }}>
                  <i className="fas fa-long-arrow-alt-left me-2"></i> Continue shopping
                </h5>
              </Link>
              <hr />
              <div className="col-lg-7 col-xxl-7 col-md-12 overflow-auto" >
                {/* Apply maxHeight and overflow-auto */}
                
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h4 className="">Checkout Detail</h4>
                    <div className="d-flex w-50">
                      <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${!needDelivery ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setNeedDelivery(false)}
                          >
                            Pickup
                          </div>
                          {/* <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${needDelivery ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setNeedDelivery(true)}
                          >
                            Delivery
                          </div> */}
                      </div>
                  
                </div>


                <div style={{ width: '100%', height: '250px' }} id="gmp-map"></div>

                <div>
                <div className="d-flex  justify-content-between align-items-center" >
                  <div>
                {needDelivery && (
                  <p className="text-danger mt-2">
                    *We deliver within 4 miles of our location*
                  </p>
                )}
                <i className="fas fa-house me-2">
                </i>
                <Link>
                274 S 20th St, Philadelphia, PA 19103
                </Link>
                  </div>
                  </div>

                <div>

                  {needDelivery && (
                <div className="d-flex  justify-content-between align-items-center mt-1" >

                  <div className="d-flex align-items-center">
                    <i className="fas fa-map-marker-alt me-2">
                    </i>
                    {currentUserAddress ? ( 
                      <div className="d-flex align-items-center ">
                      <Link>
                      {currentUserAddress}
                      </Link>
                      </div>
                    ) : (
                      <div className="d-flex flex-column">
                      {addressErrors && (
                        <p className="text-danger">{addressErrors}</p>
                        )}
                      <input
                        id="autocomplete"
                        placeholder="Enter your address"
                        style={{ width: '350px', borderRadius:'5px', padding:'5px'}}
                      />
                      </div>
                    )}
                    </div>
                    {currentUserAddress && (
                    <button className="btn btn-primary" onClick={() => {
                      setCurrentUserAddress(null);
                      setAddressVerified(false);
                      
                    }}>Edit</button>
                    )}
                  

                </div>
                  )}
                </div>
                </div>
                <div style={{borderBottom:'2px solid rgb(238, 238, 238)'}}  className="mt-2"/>
                

                <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="mb-0">You have {cartItems?.length} items in your cart</p>
                    <div >
                      <div
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                        className="text-primary"
                        >
                        Show Detail
                        <i
                          className={` fas fa-chevron-${collapsed ? "up" : "down"}`}
                          ></i>

                        </div>
                        </div>
                    </div>
                 {/* Display cart items */}
                 {cartItems?.map((item) => (
                  
                  <div key={item.id} className="card mb-3" >

                    <div
                      onClick={() => handleEditCartModal({
                        id: item?.Item.id,
                        name: item?.Item?.name,
                        price: item?.Item.price,
                        quantity: item?.quantity,
                        special_instructions: item?.special_instructions,
                        image_url: item?.Item?.image_url,
                        Restaurant_Item_Attributes: item?.Item?.Restaurant_Item_Attributes,
                        options: item?.options,
                      })}
                      className="card-body checkout-card-body  p-3 m-2"
                    
                    >
                      {/* Card content */}
                      <div className="d-flex justify-content-between" >  
                        {/* Left side */}
                        <div className="d-flex flex-row align-items-center" >
                           <div>
                            {item?.Item?.image_url && (
                              <img
                                src={item?.Item?.image_url}
                                className="rounded-3"
                                style={{ width: "65px" }}
                                alt={item?.Item?.name}
                              />
                            )}
                          </div>
                          <div className="ms-3">
                            <h5>{language === 'en' ? item?.Item?.name : item?.Item?.chinese_name}</h5>
                            {item?.options?.map((attribute) => (
                              <p className="small m-0 p-0" key={attribute.id}>
                                {getItemAttributeNameById(attribute.id, item.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                              </p>
                            ))}
                            {item?.special_instructions && (
                              <p className="small m-0 p-0">
                                <strong>**</strong> {item?.special_instructions}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* Right side */}
                        <div className="d-flex flex-row align-items-center">
                          <div style={{ width: "50px" }}>
                            <h5 className="fw-normal mb-0">{item?.quantity}</h5>
                          </div>
                          <div style={{ width: "80px" }}>
                            <h5 className="mb-0">${calculateItemTotal(item)}</h5>
                          </div>
                          <a href="#!" className="text-muted">
                            <i
                              onClick={(e) => {
                                e.stopPropagation(); // Stop event propagation
                                handleDeleteItem(item?.Item.id);
                              }}
                              className="fas fa-trash-alt"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div style={{borderBottom:'2px solid rgb(238, 238, 238)'}}  className="mt-2 mb-2"/>

              </div>
                
              {!cartItems?.length ? (
               <div className="col-md-5 d-flex justify-content-center align-items-center">
               <div className="col-lg-5 text-center" onClick={() => navigate('/online-order')}
               style={{cursor: 'pointer'}}
               >
                <p>Continue Shopping</p>
                 <Lottie
                   loop
                   animationData={emptyCartLottie}
                   play
                   style={{ width:'200px', height:'200px', margin: 'auto'}}
                 />
                 <p>Your Cart Is Empty</p>
               </div>
             </div>
             
              ) : (
                  <div className="col-lg-5 sticky-header">
                    <div className="shop-form widget">
                      <h4 className="widget-title">Order Total</h4>
                    
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 text-bold">Subtotal</p>
                        <p className="mb-0">${calculateTotal(cartItems).subtotal}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Tax</p>
                        <p className="mb-0">${calculateTotal(cartItems).tax}</p>
                      </div>
                      {!needDelivery && (
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Convenience Fee</p>
                        <p className="mb-0">${calculateTotal(cartItems).convenienceFee}</p>
                      </div>
                      )}
                      {needDelivery && (
                        <>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Service Fee</p>
                        <p className="mb-0">${calculateTotal(cartItems).service_fee}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Delivery Fee</p>
                        <p className="mb-0">${calculateTotal(cartItems)?.deliveryFee}</p>
                      </div>
                      </>
                      )}
                      <div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-0">Tip</p>
                          <p className="mb-0">${calculateTotalWithTip(cartItems, selectedTipPercentage).tipAmount}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 10 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTipOptionClick(10)}
                          >
                            10%
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 15 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTipOptionClick(15)}
                          >
                            15%
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 18 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTipOptionClick(18)}
                          >
                            18%
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 0 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={handleCustomTipClick}
                          >
                            {customTipAmount ? `${customTipAmount}$` : "Custom"}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Total</p>
                        <p className="mb-0" style={{ fontWeight: 'bold' }}>${calculateTotalWithTip(cartItems, selectedTipPercentage).total}</p>
                      </div>
                
                    

                    <p className="text-danger">
                      {needDelivery && !addressVerified ? (
                        "Please verify your address to proceed"
                      ) : null}
                    </p>
                    </div>
                    {!showPaymentModal && (
                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-hover-2 w-100 p-4"
                          type="button"
                          value="submit"
                          name="submit"
                          onClick={handleProceedToPayment}
                          disabled={!isStoreOpen() || restaurantStatus?.stop_order || !addressVerified && needDelivery}
                        >
                          {isStoreOpen() && !restaurantStatus?.stop_order ? "Proceed to Payment" : "Restaurant not open"}
                        </button>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

      <Modal
  show={showPaymentModal}
  onHide={() => setShowPaymentModal(false)}
  backdropClassName="overlay"
  dialogClassName="modal-50w"
  centered
>
  {loading ? (
    <div className="d-flex justify-content-center align-items-center" >
    <Lottie
      loop
      animationData={loader}
      play
      style={{ width: "50%", height: "100%" }}
    />
  </div>
  ) : (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Adjust the width and center the modal body */}
        {/* Payment method selection */}
       <select 
        className="form-select mb-3 w-100 p-3"
        style={{borderRadius: '5px',border: '1px solid #ced4da', textAlign: 'center', fontSize: '1.1rem', fontWeight: '600'}}
        value={selectedPaymentMethod}
        onChange={(e) => handlePaymentMethodChange(e.target.value)}
      >
        {/* {needDelivery ? opetion1.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )) : opetion2.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))} */}

        {opetion2.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

 {/* Payment action buttons */}
     
            {selectedPaymentMethod.value === "Credit Card Type" ? (
          <div>
            {error && <p className="text-danger">{error}</p>}
              <div className="row mb-2">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    style={{textAlign: 'center'}}
                    required
                    disabled={activeTab}
                    maxLength={20}
                  />
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone(10 digits, no dashes or spaces)"
                    style={{textAlign: 'center'}}
                    required
                    disabled={activeTab}
                    maxLength={10}
                  />
                </div>
                {!activeTab ? (
                  <Button variant="primary text-white" className="btn-hover-3 p-3 w-100 mt-3" onClick={handleContinueCheckout}>Continue</Button>
                  ): null }
                {activeTab && (
                  <>
                  <FontAwesomeIcon icon={faArrowLeft} onClick={goBack} style={{fontSize:'20px', padding:'5px', cursor:'pointer'}} />
                <CheckoutApp tip={calculateTotalWithTip(cartItems, selectedTipPercentage)?.tipAmount} restaurantId={restaurant?.id} name={name} phone={phone} address={needDelivery ? currentUserAddress : 'Pickup'} cartItems={cartItems}/>
                </>
                )}
                {/* <button className="btn btn-success btn-hover-2 w-100 p-4 mt-3" onClick={handlePayInStore}>Pay In Store</button> */}
              </div>
          </div>
              ) : (
                <div>
                {error && <p className="text-danger">{error}</p>}
                  <div className="row mb-2">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        style={{textAlign: 'center'}}
                        required
                        disabled={activeTab}
                        maxLength={20}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone(10 digits, no dashes or spaces)"
                        style={{textAlign: 'center'}}
                        required
                        disabled={activeTab}
                        maxLength={10}
                      />
                    </div>
                    {!activeTab ? (
                      <Button variant="primary text-white" className="btn-hover-3 p-3 w-100 mt-3" onClick={handleContinueCheckout}>Continue</Button>
                      ): null }
                    {activeTab && (
                      <>
                      <FontAwesomeIcon icon={faArrowLeft} onClick={goBack} style={{fontSize:'20px', padding:'5px', cursor:'pointer'}} />
                    <button className="btn btn-primary btn-hover-2 w-100 p-4 mt-3" onClick={handlePayInStore}>Pay In Store</button>
                    </>
                    )}
                  </div>
              </div>
              )}
        
      </Modal.Body>
    </>
  )}
</Modal>



      {/* Custom Tip Modal */}
      <Modal show={showCustomModal} onHide={() => setShowCustomModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:'1.5rem', fontWeight:'bold'}}>Enter Custom Tip Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="customTipAmount" style={{fontSize:'1rem', fontWeight:'bold', textAlign:'center'}}>Custom Tip Amount:</label>
          <InputGroup>
            <Button variant="primary text-white"  onClick={handleDecrease}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <input
              type="number"
              className="form-control"
              id="customTipAmount"
              value={customTipAmount}
              onChange={(e) => setCustomTipAmount(parseFloat(e.target.value))}
              step="1"
              min="0"
              placeholder="Enter custom tip amount"
              style={{fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center'}}
            />
            <Button variant="primary text-white" onClick={handleIncrease}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </InputGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary text-white" onClick={handleCustomTipSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>




      <EditCartModal modal={modal} setModal={setModal} item={selectedItem} />
  
    </>
  );
};

const ShopCheckout = () => {
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div className="container">
       
            <MenuList />
        </div>
      </div>
    </>
  );
};

export default ShopCheckout;
