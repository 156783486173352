// import { getToken } from "./utlits";
const apiUrl = process.env.REACT_APP_API_URL;

// const GET_CART = "cart/GET_CART";
// const ADD_ITEM = "cart/ADD_ITEM";
// const DELETE_ITEM = "cart/DELETE_ITEM";
const PLACE_ORDER = "cart/PLACE_ORDER";
// const UPDATE_ITEM = "cart/UPDATE_ITEM";

// const getCart = (cart) => ({
//     type: GET_CART,
//     cart
// })

// const addItem = (item) => ({
//     type: ADD_ITEM,
//     item
// })

// const deleteItem = (itemId) => ({
//     type: DELETE_ITEM,
//     itemId
// })

const placeOrder = (cart) => ({
    type: PLACE_ORDER,
    cart
})

// const updateItem = (item, itemId) => ({
//     type: UPDATE_ITEM,
//     item,
//     itemId
// })




// export const getCartThunk = (restaurant_id) => async (dispatch) => {
//     const token = getToken();

//     if (!token) return;
//     try {
//         const response = await fetch(`${apiUrl}/api/carts/${restaurant_id}`, { 
//             method: 'GET',
//             headers: { 
//                 'authorization': `Bearer ${token}`,
//                 'Content-Type': 'application/json',
//             },
//         });

//         if (!response.ok) {
//             throw new Error('Failed to fetch cart data');
//         }

//         const data = await response.json();
//         dispatch(getCart(data));
//     } catch (error) {
//         console.error('Error fetching cart data:', error);
//         // Optionally dispatch an action to handle the error in Redux store
//     }
// };


// export const addItemThunk = (item_id, restaurant_id, item_attribute_options_id, quantity,special_instructions ) => async (dispatch) => {
//     const token = getToken()

//     if(!token) return

//     const response = await fetch(`${apiUrl}/api/carts/`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization': `Bearer ${token}`,
//         },
//         body: JSON.stringify({item_id, restaurant_id, item_attribute_options_id, quantity, special_instructions })
//     })

//     if (response.ok) {
//         const data = await response.json();
//         dispatch(addItem(data))
//         return data
//     }

//     return response
// }

// export const updateItemThunk = (cart_item_id, item_id, restaurant_id, item_attribute_options_id, quantity, special_instructions) => async (dispatch) => {
//     const token = getToken()

    
//     const response = await fetch(`${apiUrl}/api/carts/cart_item/${cart_item_id}`, {
//         method: 'PUT',
//         body: JSON.stringify({ item_id, restaurant_id, item_attribute_options_id, quantity, special_instructions }),
//         headers: {
//             'Content-Type': 'application/json',
//             credentials: 'include',
//             'authorization': `Bearer ${token}`,
//         },
//         credentials: 'include', 
//     })

//     if (response.ok) {
//         const data = await response.json();
//         dispatch(updateItem(data,restaurant_id))
//         dispatch(getCartThunk(restaurant_id))
//         return data
//     }
// }

// export const deleteItemThunk = (cart_item_id) => async (dispatch) => { 
//     const token = getToken()


//     const response = await fetch(`${apiUrl}/api/carts/cart_item/${cart_item_id}`, { 
//         method: 'DELETE',
//         headers: { 
//             'authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json',
//             credentials: 'include',
//         },
//         credentials: 'include',

//     })

//     if (response.ok) { 
//         const data = await response.json();
//         dispatch(deleteItem(cart_item_id))
//         return data
//     }
// }

export const placeOrderThunkPayInStore = (id, paymentOption, name, phone, tip, address, cartItems) => async (dispatch) => { 
    
    const response = await fetch(`${apiUrl}/api/orders/${id}`, { 
        method: 'POST',
        body: JSON.stringify({paymentOption, name, phone, tip, address, cartItems}),
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
        },
        credentials: 'include',

    })
    
    if (response.ok) { 
        const data = await response.json();
        dispatch(placeOrder(data))
        localStorage.removeItem('cart')
    }
}


const initialState = {
  cart: [],
  order: {}
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    //   case GET_CART:
        //   return {
        //       ...state,
        //       cart: action.cart
        //   };
        // case ADD_ITEM:
        //     return {
        //         ...state,
        //         cart: [...state.cart, action.item]
        //     };
        // case DELETE_ITEM:
        //     return {
        //         ...state,
        //         cart: state.cart.filter((item) => item.id !== action.itemId)
        //     };
        case PLACE_ORDER:
            return {
                ...state,
                order: action.cart,
                cart: []
            };
      default:
          return state;
  }
};
  
  export default cartReducer;
  
