import { Swiper, SwiperSlide } from "swiper/react";
import { Home2TestimonialArr } from "../elements/JsonData";
import { Autoplay, Navigation } from "swiper/modules";

const Home2Testimonial = () => {
  return (
    <Swiper
      className="swiper testimonial-two-swiper swiper-btn-lr swiper-single swiper-visible"
      speed={1500}
      loop={true}
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 3500,
      }}
      navigation={{
        prevEl: ".testimonial-2-button-prev",
        nextEl: ".testimonial-2-button-next",
      }}
    >
      {Home2TestimonialArr.map(({ img, name, position, desc }, ind) => (
        <SwiperSlide className="swiper-slide" key={ind}>
          <div className="testimonial-2">
            <div className="dz-media">
              <div className="image-container">
                <img src={img} alt="/" className="zoom-on-hover" />
                <i className="fa-solid fa-arrow-right arrow-icon"></i>
              </div>
            </div>
            <div className="testimonial-detail">
              <div className="testimonial-text wow fadeInUp">
              </div>
                <p style={{fontSize:'18px',fontWeight:'bold'}}>
                  {desc}
                </p>
              <div className="testimonial-info wow fadeInUp">
                <h5 className="testimonial-name">{name}</h5>
                <span className="testimonial-position">{position}</span>
              </div>
              <i className="flaticon-right-quote quote"></i>
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className="pagination">
        <div className="testimonial-2-button-prev btn-prev rounded-xl btn-hover-2">
          <i className="fa-solid fa-arrow-left"></i>
        </div>
        <div className="testimonial-2-button-next btn-next rounded-xl btn-hover-2">
          <i className="fa-solid fa-arrow-right"></i>
        </div>
      </div>
    </Swiper>
  );
};

export default Home2Testimonial;
